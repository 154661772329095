import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";
import window from 'global'

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
          title="社宅公共藝術宣言 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
          meta={[
            {
              name: `description`,
              content: "給家一個新概念 / 市民共享共創共治的家園 / 跨越世代族群與階級/人類自然親賴共榮北市社宅公共藝術，以此宣言，台北是所有人的家園，不遺落邊緣弱勢的人們；關心他們，與所有人腳下的這片土地。建立新生活，也創造新生活文化，認識彼此的過去，共同書寫新的未來。",
            },
            {
              property: `og:title`,
              content: "社宅公共藝術宣言 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
            },
            {
              property: `og:description`,
              content: "給家一個新概念 / 市民共享共創共治的家園 / 跨越世代族群與階級/人類自然親賴共榮北市社宅公共藝術，以此宣言，台北是所有人的家園，不遺落邊緣弱勢的人們；關心他們，與所有人腳下的這片土地。建立新生活，也創造新生活文化，認識彼此的過去，共同書寫新的未來。",
            }
          ]}
         >
         </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;